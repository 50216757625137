import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import wxAuth from '../views/wxAuth.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'index',
    component: Index
  },
  {
    path: '/wxAuth',
    name: 'wxAuth',
    component: wxAuth
  },
  {
    path: '/flowCard',
    name: 'flowCard',
    component: () => import('../views/flowCard')
  },
  {
    path: '/flowActivation',
    name: 'flowActivation',
    component: () => import('../views/flow/flowActivation.vue')
  },
   {
    path: '/flowBuy',
    name: 'flowBuy',
    component: () => import('../views/flow/flowBuy.vue')
  },
    {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('../views/flow/Privacy.vue')
  },
     {
    path: '/flowLoading',
    name: 'flowLoading',
    component: () => import('../views/flow/flowLoading.vue')
  },
      {
    path: '/indicate',
    name: 'indicate',
    component: () => import('../views/flow/indicate.vue')
  },
       {
    path: '/service',
    name: 'service',
    component: () => import('../views/flow/service.vue')
  },
    {
    path: '/Mobiledevice',
    name: 'Mobiledevice',
    component: () => import('../views/Mobile/Mobiledevice.vue')
  },
    {
    path: '/Mobiledpay',
    name: 'Mobiledpay',
    component: () => import('../views/Mobile/Mobiledpay.vue')
  },
    {
    path: '/MobliedCesi',
    name: 'MobliedCesi',
    component: () => import('../views/Mobile/MobliedCesi.vue')
  },
    {
    path: '/MobliedBuy',
    name: 'MobliedBuy',
    component: () => import('../views/Mobile/MobliedBuy.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
})

export default router
